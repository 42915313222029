.blockCard {
  position: relative;
}

.imgCard {
  width: 340px;
  height: 340px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 15%;
}

.titreCard {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  color: #fff;
  font-size: 18px;
  max-width: 45%;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}
@media all and (max-width: 1200px) {
  .imgCard {
    max-width: 100%;
    max-height: 300px;
    min-width: 100%;
  }
}
@media all and (max-width: 780px) {
  .imgCard {
    max-width: 100%;
    max-height: 255px;
    margin-top: 22px;
  }
}
