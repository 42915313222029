.rating {
  display: flex;
  gap: 10%;
  justify-content: flex-end;
  margin-top: 27px;
}

@media all and (max-width: 991px) {
  .etoile {
    max-width: 20px;
    max-height: 20px;
  }
  .rating {
    gap: 10%;
    justify-content: flex-start;
  }
}
@media all and (max-width: 767px) {
  .etoile {
    max-width: 13px;
    max-height: 13px;
  }
}
