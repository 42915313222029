.footer {
  max-height: 209px;
  min-height: 114px;
  width: 100%;
  background-color: black;
  text-align: center;
  align-items: center;
  padding-top: 66px;
  padding-bottom: 29px;
  margin-top: 100px;
}

.logoFooter {
  max-width: 122px;
  max-height: 40px;
}

.textFooter {
  color: white;
  max-height: 46px;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
}
@media all and (max-width: 991px) {
  .textFooter {
    font-size: 19px;
  }
}
@media all and (max-width: 767px) {
  .textFooter {
    font-size: 12px;
  }
  .footer {
    margin-top: 70px;
  }
}
