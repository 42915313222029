.divBanner {
  min-width: 100%;
  max-width: 100%;
  min-height: 223px;
  justify-content: center;
  display: flex;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blockThumb {
  gap: 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  background-color: #f6f6f6;
  margin-top: 43px;
  border-radius: 25px;
  margin: 2%;
  margin-left: 7%;
  margin-right: 7%;
  padding-bottom: 3.5%;
  padding-top: 1.5%;
}

@media all and (max-width: 1300px) {
  .blockThumb {
    background-color: white;
    min-width: 95%;
    margin-top: 0px;
    justify-content: space-around;
  }
}
@media all and (max-width: 780px) {
  .blockThumb {
    background-color: white;
    display: block;
    max-width: 95%;
    margin-top: 0px;
  }
  .divBanner {
    max-height: 111px;
    min-height: 111px;
  }
}
